@font-face {
    font-family: 'Inter';
    src: local('Inter'),
        url(./assets/fonts/English/Inter-Regular-slnt=0.ttf) format('truetype');
}

/* Transaction styles */
.transactionItemContainer {
    display: grid;
    text-align: center;
    border-bottom: thin solid #f0f0f0;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 100px 100px 120px;
}

.transactionItemContainer > div {
    padding: 15px 0;
    align-self: center;
}

.ant-picker {
    width: 100%;
}
html.rtl,
.rtl body {
    direction: rtl;
    /* font-family: 'kurdish_regular' !important; */
}

/* .contract-sale-menu .ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin: 0;
} */

.short_cut_cards {
    width: 100%;
    border-radius: 8px;
    transition: all 0.3s;
    cursor: pointer;
    text-align: center;
}

.short_cut_cards:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.15);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.15);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.15);
}
.short_cut_cards svg {
    transition: all 0.3s;
}
.short_cut_cards:hover svg {
    color: #008ef398;
}

.custom_partial_table .ant-form-item {
    margin-bottom: 0;
}

.account_access_container .ant-transfer-list {
    width: 50%;
    height: 300px;
    overflow: hidden;
    border-radius: 5px;
}

.ant-dropdown .ant-menu-item .anticon,
.ant-dropdown .ant-menu-submenu-title .anticon {
    margin: 0;
}

/* About us page */
.About_us_container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.About_us_container h1 {
    text-align: center;
    color: #d32d41;
    font-size: 40px;
    font-weight: 900;
    margin: 2.5rem 0 4rem;
}

.our_mission {
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    margin-bottom: 10rem;
}

.our_mission_container {
    width: calc(100% - 550px);
}

.our_mission_container > a {
    color: #000000;
    font-weight: bold;
    margin-top: 1.5rem;
    display: block;
}

.our_mission_container p {
    line-height: 1.7;
    color: gray;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.our_mission_img {
    width: 450px;
}

.our_mission_container .anticon-arrow-right {
    transition: all 0.3s;
}

.our_mission_container .ant-space:hover .ant-space-item {
    color: #d32d41;
}

.our_mission_container .ant-space:hover .anticon-arrow-right {
    transform: translate(10px, 0);
}

.contact_us_container {
    display: flex;
    justify-content: space-evenly;
}

.contact_us_container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    width: 175px;
}

.contact_us_container > div img {
    width: 40px;
    height: 40px;
}

.contact_us_container > div div span {
    font-size: 20px;
    color: #d32d41;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.floating_img {
    width: 60px;
    height: 60px;
    background: #d32d41;
    box-shadow: 0px 0px 9px 2px #223c505e;
    border-radius: 100px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.floating_img img {
    width: 25px;
}

.videoModal .ant-modal-body {
    padding: 0 !important;
}

.videoModal .ant-modal-close {
    transition: all 0.3s;
    background: #ffffffd1;
    border-bottom-left-radius: 8px;
}

.videoModal .ant-modal-close:hover {
    color: #222;
}
